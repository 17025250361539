import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import NotFound from "./pages/notFound/404";
import TermsOfService from "./pages/termsOfService/termsOfService";
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy";
import Help from "./pages/help/help";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/help" element={<Help />} />
        <Route path="*" element={<NotFound />} />
        <Route element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
