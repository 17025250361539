import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showSuccessToast, showErrorToast } from "../../utils/toast";

import "./contactUs.scss";

function ContactUs() {
  const passCheckAns = process.env.REACT_APP_PASS_CHECK;
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    passCheck: passCheckAns,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("https://control-room-mail-api.onrender.com/send-email", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        showSuccessToast("Email Sent Successfully");
        setFormData({ firstName: "", email: "" });
      })
      .catch((error) => {
        showErrorToast("Email Failed to send. Please try again later");
      });
  };

  return (
    <div className="contact-us" id="contact-us">
      <ToastContainer />
      <h2>Contact Us</h2>
      <h4>Sign up today and take control of your cold email campaigns.</h4>
      <form onSubmit={handleSubmit}>
        <div className="contact-us__input-container">
          <label htmlFor="firstName">Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="Name"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="contact-us__input-container">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="button">
          Get In Touch
        </button>
      </form>
    </div>
  );
}

export default ContactUs;
