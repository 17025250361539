import React from "react";
import { Link } from "react-router-dom";

import "./footer.scss";
//Images
import logo from "../../images/logo-v2.svg";
import linkedIn from "../../images/linkedin.svg";

function Footer() {
  const smoothScroll = (target) => {
    const element = document.getElementById(target);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth", // Smooth scrolling behavior
      });
    }
  };
  return (
    <div className="footer-container">
      <div className="footer-container__footer">
        <div className="footer-container__footer__left">
          <img
            src={logo}
            alt="Company Logo"
            onClick={() => smoothScroll("navbar")}
          />
          <p>All Your Cold Email Data in One Dashboard</p>
        </div>
        <div className="footer-container__footer__right">
          <ul>
            <li onClick={() => smoothScroll("how-it-works")}>Product</li>
            <li onClick={() => smoothScroll("pricing")}>Pricing</li>
            {/* <li onClick={() => smoothScroll("sign-up")}>Sign Up</li> */}
            <li onClick={() => smoothScroll("contact-us")}>Contact</li>
          </ul>
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/company/ctrlroom-ai/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <img src={linkedIn} alt="" />
              </a>{" "}
              <a
                href="https://www.linkedin.com/company/ctrlroom-ai/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>{" "}
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-container__docs">
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-of-service">Terms of Service</Link>
      </div>
    </div>
  );
}

export default Footer;
