import React, { useState, useEffect, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

import "./dashboards.scss";
import "swiper/css/pagination";

//Images
import AnalyticsDashboardSvg from "../../images/analyticsDashboardSvg/analyticsDashboardSvg";
import CopyDashboardSvg from "../../images/copyDashboardSvg/copyDashboardSvg";
import PersonasDashboardSvg from "../../images/personasDashboardSvg/personasDashboardSvg";
import ContactsDashboardSvg from "../../images/contactsDashboardSvg/contactsDashbaordSvg";
import analyticsLaptop from "../../images/analytics-laptop.png";
import copyLaptop from "../../images/copy-laptop.png";
import personasLaptop from "../../images/personas-laptop.png";
import contactsLaptop from "../../images/contacts-laptop.png";

function Dashboards() {
  const [currentDashboard, setCurrentDashboard] = useState("analytics");
  const setDashboard = (dashboard) => {
    setCurrentDashboard(dashboard);
  };
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  const analyticsTexts = useMemo(
    () => [
      "Total Leads",
      "Top Persona",
      "Total Campaigns",
      "Campaign Volume",
      "Total Leads & Email Metrics",
      "Reply Rate",
    ],
    []
  );

  const copyText = useMemo(
    () => [
      "Best performing variant",
      "Worst performing variant",
      "Interested/Not Interested rate per variant",
      "Copy Breakdown Table",
    ],
    []
  );

  const personasText = useMemo(
    () => [
      "Top Persona Breakdown",
      "Industry, Job Title & Size",
      "Personas Breakdown Table",
    ],
    []
  );

  const contactsText = useMemo(() => ["All Contact Info"], []);

  const textArrays = useMemo(
    () => [analyticsTexts, copyText, personasText, contactsText],
    [analyticsTexts, copyText, personasText, contactsText]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex(
        (prevIndex) => (prevIndex + 1) % textArrays[currentSlideIndex].length
      );
    }, 2000); // Change text every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [currentSlideIndex, textArrays]);

  return (
    <div className="dashboards">
      <div className="dashboards__mobile">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 18000,
            disableOnInteraction: false,
          }}
          onSlideChange={(swiper) => {
            setCurrentSlideIndex(swiper.realIndex);
            setCurrentTextIndex(0); // Reset text index when slide changes
          }}
        >
          <SwiperSlide>
            <img
              src={analyticsLaptop}
              alt="Analytics dashboard displaying key performance metrics"
            />
            <div id="rotating-text">
              {analyticsTexts.map((text, index) => (
                <p
                  key={index}
                  className={index === currentTextIndex ? "active" : ""}
                >
                  {text}
                </p>
              ))}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={copyLaptop}
              alt="Copy dashboard showing insights on copy performance for deliverability, leads, and replies"
            />
            <div id="rotating-text">
              {copyText.map((text, index) => (
                <p
                  key={index}
                  className={index === currentTextIndex ? "active" : ""}
                >
                  {text}
                </p>
              ))}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={personasLaptop}
              alt="Personas Dashboard analyzing ICP, prospect engagement, and personas to avoid"
            />
            <div id="rotating-text">
              {personasText.map((text, index) => (
                <p
                  key={index}
                  className={index === currentTextIndex ? "active" : ""}
                >
                  {text}
                </p>
              ))}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={contactsLaptop}
              alt="Contacts dashboard for filtering contact data, viewing leads, threads, and replies"
            />
            <div id="rotating-text">
              {contactsText.map((text, index) => (
                <p
                  key={index}
                  className={index === currentTextIndex ? "active" : ""}
                >
                  {text}
                </p>
              ))}
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="dashboards__menu">
        <ul>
          <li
            className={currentDashboard === "analytics" ? "menu-active" : null}
            onClick={() => {
              setDashboard("analytics");
            }}
          >
            Analytics Dashboard
          </li>
          <li
            className={currentDashboard === "copy" ? "menu-active" : null}
            onClick={() => {
              setDashboard("copy");
            }}
          >
            Copy Dashboard
          </li>
          <li
            className={currentDashboard === "personas" ? "menu-active" : null}
            onClick={() => {
              setDashboard("personas");
            }}
          >
            Personas Dashboard
          </li>
          <li
            className={currentDashboard === "contacts" ? "menu-active" : null}
            onClick={() => {
              setDashboard("contacts");
            }}
          >
            Contacts
          </li>
        </ul>
      </div>
      {currentDashboard === "analytics" ? (
        <div className="dashboards__images">
          <h5>
            Analytics Dashboard gives you an overview of lead volumes and shows
            you how to increase lead flow by allocating email volume to your
            best campaigns.
          </h5>

          <AnalyticsDashboardSvg />
        </div>
      ) : currentDashboard === "copy" ? (
        <div className="dashboards__images">
          <h5>
            Copy Dashboard offers detailed insights as to which copy to double
            down on to maximise deliverability, leads and replies.
          </h5>

          <CopyDashboardSvg />
        </div>
      ) : currentDashboard === "personas" ? (
        <div className="dashboards__images">
          <h5>
            An in depth analysis of your ICP, who to prospect more of, who's
            engaging the most and persona's to avoid.
          </h5>
          <PersonasDashboardSvg />
        </div>
      ) : (
        <div className="dashboards__images">
          <h5>
            Never let a lead fall through the cracks. Easily filter through your
            contact data, view leads, threads and replies with one click.
          </h5>
          <ContactsDashboardSvg />
        </div>
      )}
    </div>
  );
}

export default Dashboards;
