import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";

import "./reviews.scss";
//Images
import star from "../../images/star.svg";

function Reviews() {
  const FiveStars = () => {
    // Array with 5 elements (you can use any value since it's just for looping)
    const images = new Array(5).fill(null);

    return (
      <div className="image-container">
        {images.map((_, index) => (
          <img
            key={index}
            src={star} // Replace with the path to your image
            alt={`Star ${index + 1}`} // Alternative text for the image
          />
        ))}
      </div>
    );
  };
  return (
    <div className="reviews">
      <h2>What Our Customers Say</h2>

      <Swiper
        slidesPerView={3}
        spaceBetween={40}
        loop={true} // Enables continuous loop mode
        speed={5000} // Adjust the speed to make the transition smoother
        autoplay={{
          delay: 1000, // Makes the autoplay continuous
          disableOnInteraction: true,
        }}
        modules={[Autoplay]}
        freeMode={true}
        breakpoints={{
          // when window width is >= 320px
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 480px
          1000: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          // when window width is >= 640px
          1300: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
      >
        <SwiperSlide>
          <h4>Soho</h4>
          <div>
            <p>
              Ctrlroom has done a great job of gathering cold leads for Soho. I
              have had a good experience with customer service even with the
              huge time difference. The reporting features are incredibly
              helpful in shaping our future campaigns. Ctrlroom’s team is always
              looking to enhance the effectiveness of our campaigns.
            </p>
            {FiveStars()}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <h4>B2B Leads</h4>
          <div>
            <p>
              As we were growing our team, selecting a lead gen partner that
              could help us dial up our lead generation without taking up our
              time and resources was pivotal. Like most, I was originally
              skeptical about responding to a cold email outreach, however I was
              impressed with the advanced infrastructure and data analytics in
              the Ctrlroom
            </p>
            {FiveStars()}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <h4>
            House Of <br />
            Macadamias
          </h4>
          <div>
            <p>
              If your business is ready for scale, FueltoFly is the ultimate
              partner. The professionalism, customer service the team provides
              and Ctrlroom is literally 24/7. It’s like having your own dozen
              full-time business development reps with a fraction of the cost.
            </p>
            {FiveStars()}
          </div>
        </SwiperSlide>
      </Swiper>
      {/* <Swiper
        slidesPerView={3}
        spaceBetween={30}
        loop={true} // Enables continuous loop mode
        speed={5000} // Adjust the speed to make the transition smoother
        autoplay={{
          delay: 0, // Makes the autoplay continuous
          disableOnInteraction: true,
          reverseDirection: true,
        }}
        modules={[Autoplay]}
        freeMode={true}
        direction={"vertical"}
        className="desktop"
      >
        
        
      </Swiper> */}
    </div>
  );
}

export default Reviews;
