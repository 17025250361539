import React from "react";

import "./howItWorks.scss";

//Images
import analysis from "../../images/analysis.svg";
import feedback from "../../images/feedback.svg";
import contact from "../../images/contact.svg";
import insight from "../../images/insight.svg";
import integration from "../../images/integration.svg";

function HowItWorks() {
  return (
    <div className="how-it-works" id="how-it-works">
      <h2>How It Works</h2>
      <div className="how-it-works__grid">
        <div className="how-it-works__grid__large grid-item">
          <div className="how-it-works__grid__large__text">
            <img className="icon" src={integration} alt="Integration Icon" />
            <div className="how-it-works__grid__large__text__bottom">
              <h3>Easy Integration</h3>
              <p>
                Have all your insights within seconds after adding your
                Smartlead API key.
              </p>
            </div>
          </div>
        </div>

        <div className="how-it-works__grid__small grid-item">
          <img className="icon" src={analysis} alt="Analytics Icon" />
          <div className="how-it-works__grid__small__text">
            <h3>Comprehensive Analytics</h3>
            <p>
              Easily learn where to allocate your email volume to maximise
              leadflow.
            </p>
          </div>
        </div>

        <div className="how-it-works__grid__small grid-item">
          <img className="icon" src={insight} alt="Insight Icon" />
          <div className="how-it-works__grid__small__text">
            <h3>Persona level insights</h3>
            <p>
              Refine your ICP after seeing reply rates and leads by job title,
              industry, headcount and every combination within.
            </p>
          </div>
        </div>
        <div className="how-it-works__grid__small grid-item">
          <img className="icon" src={feedback} alt="Copy Feedback Icon" />
          <div className="how-it-works__grid__small__text">
            <h3>Copy feedback</h3>
            <p>
              See all your copy variants performance, learn which copy drives
              leads.
            </p>
          </div>
        </div>
        <div className="how-it-works__grid__small grid-item">
          <img className="icon" src={contact} alt="Contacts Icon" />
          <div className="how-it-works__grid__small__text">
            <h3>Contacts</h3>
            <p>All your contacts, searchable & filterable, in one place.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
