import React from "react";

import "./hero.scss";

//Images
import arrow from "../../images/yellow-arrow.svg";

function Hero() {
  return (
    <div className="hero">
      <div className="hero__text">
        <h1>Master Your Cold Emails with The Ctrl Room</h1>
        <h4>All Your Cold Email Data in One Dashboard</h4>
        <div className="plus">+</div>
        <h4>Effortless Smartlead integration </h4>
        <div className="hero__text__buttons">
          <a
            href="https://app.staging.ctrlroom.ai/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>For Me</button>
            <img src={arrow} alt="Arrow pointing to the right" />
          </a>
          <a
            href="https://app.staging.ctrlroom.ai/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="for-my-client"> For My Clients</button>
            <img src={arrow} alt="Arrow pointing to the right" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Hero;
