import React from "react";

import "./notFound.scss";

//Images
import errorNotFound from "../../images/error.svg";

function NotFound() {
  return (
    <div className="not-found">
      <div className="not-found__text">
        <p>404 error</p>
        <h2>Can’t Find Page</h2>
        <h4>
          Sorry, the page you are looking for doesn't exist or has been moved.
        </h4>
      </div>
      <img src={errorNotFound} alt="404 Error" />
    </div>
  );
}

export default NotFound;
