import React from "react";

import "./termsOfService.scss";

function TermsOfService() {
  return (
    <div className="terms-of-service">
      <h1>Terms Of Service</h1>
      <p>[Effective date: 15 July 2024]</p>

      <div className="terms-of-service__points">
        <ol>
          <li>
            Acceptance of Terms{" "}
            <p>
              By accessing and using The Ctrlroom app ("the App"), marketed by
              ctrlroom.ai ("the Website"), you agree to comply with and be bound
              by these Terms of Service ("Terms"). If you do not agree to these
              Terms, please do not use the App.
            </p>
          </li>
          <li>
            Use of the App{" "}
            <p>
              You agree to use the App in accordance with all applicable laws
              and regulations. You are responsible for maintaining the
              confidentiality of your account information and for all activities
              that occur under your account.
            </p>
          </li>
          <li>
            Intellectual Property{" "}
            <p>
              All content in the App, including text, graphics, logos, and
              software, is the property of Ctrlroom or its licensors and is
              protected by intellectual property laws. You may not use any
              content from the App without the express written permission of
              Ctrlroom.
            </p>
          </li>
          <li>
            User Conduct <p>You agree not to:</p>
            <ul>
              <li>Use the App for any unlawful purpose.</li>
              <li>Interfere with or disrupt the App or servers.</li>
              <li>Transmit any viruses or harmful code.</li>
            </ul>
          </li>
          <li>
            Data Analytics{" "}
            <p>
              The App provides data analytics by displaying data from 521
              Products Pty Ltd t/a Smartlead.ai (“Smartlead”). You agree that
              Ctrlroom is not responsible for the accuracy or completeness of
              data provided by Smartlead.ai.
            </p>
          </li>
          <li>
            Limitation of Liability{" "}
            <p>
              Ctrlroom is not liable for any direct, indirect, incidental, or
              consequential damages arising from your use of the App. The App is
              provided "as is" without any warranties of any kind.
            </p>
          </li>
          <li>
            Termination{" "}
            <p>
              Ctrlroom reserves the right to terminate or suspend your access to
              the App at any time, without notice, for conduct that violates
              these Terms or is otherwise harmful to other users or the business
              interests of Ctrlroom.
            </p>
          </li>
          <li>
            Changes to these Terms of Service{" "}
            <p>
              We may update the Terms of Service from time to time. We will
              notify you of any changes by posting the new Terms of Service on
              the Website and updating the "Effective Date" at the top.
            </p>
          </li>
          <li>
            Governing Law{" "}
            <p>
              These Terms are governed by and construed in accordance with the
              laws of the jurisdiction in which Ctrlroom operates, without
              regard to its conflict of law principles.
            </p>
          </li>
          <li>
            Contact Information{" "}
            <p>
              If you have any questions or concerns about these terms, please
              contact us at{" "}
              <a href="mailto:support@ctrlroom.ai">support@ctrlroom.ai</a>
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default TermsOfService;
