import React from "react";

import "./home.scss";
//Images

import Hero from "../../components/hero/hero";
import Dashboards from "../../components/dashboards/dashboards";
import HowItWorks from "../../components/howItWorks/howItWorks";
import Reviews from "../../components/reviews/reviews";
import Pricing from "../../components/pricing/pricing";
import CallToAction from "../../components/callToAction/callToAction";
import ContactUs from "../../components/contactUs/contactUs";

function Home() {
  return (
    <div className="home">
      <Hero />
      <Dashboards />
      <Reviews />
      {/* <CallToAction /> */}
      <Pricing />
      <HowItWorks />
      <ContactUs />
    </div>
  );
}

export default Home;
