import React, { useState } from "react";
import "./navbar.scss";
import { Link } from "react-router-dom";

//Images
import logo from "../../images/logo-v2.svg";
import mobileMenuOpen from "../../images/mobile-menu-icon.svg";
import mobileMenuClose from "../../images/mobile-menu-close.svg";

function Navbar() {
  const [mobileDisplaying, setMobileDisplaying] = useState(false);
  const smoothScroll = (target) => {
    setMobileDisplaying(false);
    const element = document.getElementById(target);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth", // Smooth scrolling behavior
      });
    }
  };
  return (
    <div className="navbar" id="navbar">
      <nav className="navbar__desktop">
        <div className="navbar__side">
          <Link to="/" className="navbar__side__logo">
            <img src={logo} alt="Company Logo" />
          </Link>
        </div>

        <ul>
          <li onClick={() => smoothScroll("how-it-works")}>Product</li>
          <li onClick={() => smoothScroll("pricing")}>Pricing</li>
          {/* <li onClick={() => smoothScroll("sign-up")}>Sign Up</li> */}
          <li onClick={() => smoothScroll("contact-us")}>Contact</li>

          <a
            href="https://app.staging.ctrlroom.ai/"
            target="_blank"
            rel="noopener noreferrer"
            className="login"
          >
            Login
          </a>
          <a
            href="https://app.staging.ctrlroom.ai/register"
            target="_blank"
            rel="noopener noreferrer"
            className="signup"
          >
            <button className="button">Sign Up</button>
          </a>
        </ul>
      </nav>
      {mobileDisplaying ? (
        <nav className="navbar__mobile">
          <img
            src={mobileMenuClose}
            alt="Mobile Menu Close Icon"
            className="navbar__mobile__close"
            onClick={() => {
              setMobileDisplaying(false);
            }}
          />

          <div className="navbar__mobile__side">
            <Link to="/">
              <img src={logo} alt="Company Logo" />
            </Link>
          </div>

          <ul>
            <li onClick={() => smoothScroll("how-it-works")}>Product</li>
            <li onClick={() => smoothScroll("pricing")}>Pricing</li>
            <li onClick={() => smoothScroll("sign-up")}>Sign Up</li>
            <li onClick={() => smoothScroll("contact-us")}>Contact</li>
          </ul>
        </nav>
      ) : (
        <img
          src={mobileMenuOpen}
          alt="Mobile Menu Icon"
          className="navbar__mobile__open"
          onClick={() => {
            setMobileDisplaying(true);
          }}
        />
      )}
    </div>
  );
}

export default Navbar;
