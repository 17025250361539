import React from "react";

import "./help.scss";
//Images
import stepOne from "../../images/help/step-1.png";
import stepTwo from "../../images/help/step-2.png";
import stepThree from "../../images/help/step-3.png";
import stepFour from "../../images/help/step-4.png";
import stepFive from "../../images/help/step-5.png";
import stepSix from "../../images/help/step-6.png";

function Help() {
  const steps = [
    { title: "Step 1:", img: stepOne },
    { title: "Step 2:", img: stepTwo },
    { title: "Step 3:", img: stepThree },
    { title: "Step 4:", img: stepFour },
    { title: "Step 5:", img: stepFive },
    { title: "Step 6:", img: stepSix },
  ];
  return (
    <div className="help">
      {steps.map((step, index) => (
        <div key={index} className="help__image-container">
          <h2>{step.title}</h2>
          <img src={step.img} alt={`Image for ${step.title}`} />
        </div>
      ))}
    </div>
  );
}

export default Help;
