import React, { useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Tooltip from "rc-tooltip";

import "./pricing.scss";
//Images

function Pricing() {
  const [value, setValue] = useState(0);
  const [valueEmbark, setValueEmbark] = useState(0);

  const marks = {
    0: "10 000",
    1: "25 000",
    2: "50 000",
    3: "75 000",
    4: "100 000",
    5: "150 000",
    6: "200 000",
    7: "200 000+",
  };

  const marksEmbark = {
    0: "3 clients / 30 000 contacts",
    1: "4 clients / 40 000 contacts",
    2: "5 clients / 50 000 contacts",
    3: "6 clients / 60 000 contacts",
    4: "7 clients / 70 000 contacts",
    5: "8 clients / 80 000 contacts",
    6: "9 clients / 90 000 contacts",
    7: "10 clients / 100 000 contacts",
    8: "11 clients / 110 000 contacts",
    9: "12 clients / 120 000 contacts",
    10: "13 clients / 130 000 contacts",
    11: "14 clients / 140 000 contacts",
    12: "15 clients / 150 000 contacts",
    13: "15 clients +",
  };

  const handleChange = (value) => {
    if (value > 6) {
      setValue(7); // Representing 500K+
    } else {
      setValue(value);
    }
  };

  const handleChangeEmbark = (valueEmbark) => {
    if (valueEmbark > 12) {
      setValueEmbark(13); // Representing 500K+
    } else {
      setValueEmbark(valueEmbark);
    }
  };

  return (
    <div className="pricing" id="pricing">
      <h2>Choose Your Plan</h2>
      <div className="pricing__panels">
        <div className="pricing__panels__panel">
          <div className="pricing__panels__panel__top">
            <h3>$0/mo</h3>
            <h4>Free</h4>
            <div className="pricing__panels__panel__top__slider__totals">
              <p>5000 contacts</p>
            </div>
          </div>

          <ul>
            <li> 1x Smartlead API integration </li>
            <li> Setup support </li>
            <li> Data on 5000 contacts</li>
          </ul>

          <a
            href="https://app.staging.ctrlroom.ai/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>Get Started</button>
          </a>
        </div>
        <div className="pricing__panels__panel">
          <div className="pricing__panels__panel__top">
            {marks[value] === "10 000" ? (
              <h3>$19/mo</h3>
            ) : marks[value] === "25 000" ? (
              <h3>$29/mo</h3>
            ) : marks[value] === "50 000" ? (
              <h3>$55/mo</h3>
            ) : marks[value] === "75 000" ? (
              <h3>$89/mo</h3>
            ) : marks[value] === "100 000" ? (
              <h3>$109/mo</h3>
            ) : marks[value] === "150 000" ? (
              <h3>$149/mo</h3>
            ) : marks[value] === "200 000" ? (
              <h3>$219/mo</h3>
            ) : (
              <h3>Contact Us</h3>
            )}
            <h4>Starter</h4>
            <div className="pricing__panels__panel__top__slider">
              <Slider
                min={0}
                max={7}
                step={null}
                marks={marks}
                onChange={handleChange}
                value={value}
                handleStyle={{
                  borderColor: "transparent",
                  height: 20,
                  width: 20,
                  marginLeft: -10,
                  marginTop: -8,
                  backgroundColor: "var(--accent-color)",
                  opacity: 1,
                }}
              />
              <div className="pricing__panels__panel__top__slider__totals">
                <p>{marks[value]} contacts</p>
              </div>
            </div>
          </div>

          <ul>
            <li> 1x Smartlead API integration </li>
            <li> Setup support </li>
            <li> Starting at 10k contacts</li>
          </ul>

          <a
            href="https://app.staging.ctrlroom.ai/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>Start 7-day Trial</button>
          </a>
        </div>
        <div className="pricing__panels__panel">
          <div className="pricing__panels__panel__top">
            {marksEmbark[valueEmbark] === "3 clients / 30 000 contacts" ? (
              <h3>$49/mo</h3>
            ) : marksEmbark[valueEmbark] === "4 clients / 40 000 contacts" ? (
              <h3>$68/mo</h3>
            ) : marksEmbark[valueEmbark] === "5 clients / 50 000 contacts" ? (
              <h3>$87/mo</h3>
            ) : marksEmbark[valueEmbark] === "6 clients / 60 000 contacts" ? (
              <h3>$106/mo</h3>
            ) : marksEmbark[valueEmbark] === "7 clients / 70 000 contacts" ? (
              <h3>$125/mo</h3>
            ) : marksEmbark[valueEmbark] === "8 clients / 80 000 contacts" ? (
              <h3>$144/mo</h3>
            ) : marksEmbark[valueEmbark] === "9 clients / 90 000 contacts" ? (
              <h3>$163/mo</h3>
            ) : marksEmbark[valueEmbark] === "10 clients / 100 000 contacts" ? (
              <h3>$182/mo</h3>
            ) : marksEmbark[valueEmbark] === "11 clients / 110 000 contacts" ? (
              <h3>$201/mo</h3>
            ) : marksEmbark[valueEmbark] === "12 clients / 120 000 contacts" ? (
              <h3>$220/mo</h3>
            ) : marksEmbark[valueEmbark] === "13 clients / 130 000 contacts" ? (
              <h3>$239/mo</h3>
            ) : marksEmbark[valueEmbark] === "14 clients / 140 000 contacts" ? (
              <h3>$258/mo</h3>
            ) : marksEmbark[valueEmbark] === "15 clients / 150 000 contacts" ? (
              <h3>$277/mo</h3>
            ) : (
              <h3>Contact Us</h3>
            )}

            <h4>Agency</h4>
            <div className="pricing__panels__panel__top__slider">
              <Slider
                min={0}
                max={13}
                step={null}
                marks={marksEmbark}
                onChange={handleChangeEmbark}
                value={valueEmbark}
                handleStyle={{
                  borderColor: "transparent",
                  height: 20,
                  width: 20,
                  marginLeft: -10,
                  marginTop: -8,
                  backgroundColor: "var(--accent-color)",
                  opacity: 1,
                }}
              />
              <div className="pricing__panels__panel__top__slider__totals">
                <p>{marksEmbark[valueEmbark]}</p>
              </div>
            </div>
          </div>
          <ul>
            <li>Includes 3 client dashboards</li>
            <li> 1x Smartlead API integration </li>
            <li> Setup support </li>
            <li>
              Client Access (separate logins per client, they view only their
              campaigns) $19/mo per client
            </li>
            <li> Additional contacts at $10 per 10 000 contacts </li>
          </ul>
          <a
            href="https://app.staging.ctrlroom.ai/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>Start 7-day Trial</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
