import React from "react";

import "./privacyPolicy.scss";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>[Effective date: 15 July 2024]</p>

      <div className="privacy-policy__points">
        <ol>
          <li>
            Introduction{" "}
            <p>
              ctrlroom.ai ("we", "our", "us") is committed to protecting your
              privacy. This Privacy Policy explains how we collect, use,
              disclose, and safeguard your information when you use The Ctrlroom
              app ("the App").
            </p>
          </li>
          <li>
            Information We Collect{" "}
            <ul>
              <li>
                Personal Information: Name, work email address, company name,
                company website and other contact details.
              </li>
              <li>
                Usage Data: Information about how you use the App, including
                access times and pages viewed.
              </li>
              <li>
                Device Information: Information about the device you use to
                access the App, such as IP address, browser type, and operating
                system.
              </li>
              <li>
                Data from Smartlead: Information provided by 521 Products Pty
                Ltd t/a Smartlead.ai ("Smartlead"), including email addresses,
                lead data, and campaign analytics.
              </li>
            </ul>
          </li>
          <li>
            How We Use Your Information{" "}
            <ul>
              <li>To provide and maintain the App</li>
              <li>To improve the App and our services</li>
              <li>
                To communicate with you, including sending updates and
                promotional materials
              </li>
              <li>
                To monitor and analyse usage and trends to improve your
                experience
              </li>
            </ul>
          </li>
          <li>
            Smartlead Data Handling{" "}
            <ul>
              <li>
                Data Ownership: All data provided by Smartlead remains the
                property of the user. We do not claim any ownership over the
                data imported from Smartlead.
              </li>
              <li>
                Data Storage: Data from Smartlead is stored in a SOC 2 compliant
                database, ensuring high standards of security and compliance.
              </li>
              <li>
                Encryption: API keys used to access Smartlead data are encrypted
                to protect them from unauthorised access.
              </li>
              <li>
                Data Usage: We only export, display, and process data from
                Smartlead as necessary to provide the functionalities of The
                Ctrlroom app.
              </li>
            </ul>
          </li>
          <li>
            Sharing Your Information{" "}
            <ul>
              <li>
                Service Providers: Third-party vendors that help us operate and
                improve the App.
              </li>
              <li>
                Compliance with Laws: When required by law or to protect our
                rights.
              </li>
              <li>
                Business Transfers: In connection with any merger, sale of
                company assets, or financing.
              </li>
            </ul>
          </li>
          <li>
            Data Security{" "}
            <p>
              We use administrative, technical, and physical security measures
              to protect your personal information. However, no method of
              transmission over the internet or electronic storage is completely
              secure.
            </p>
          </li>
          <li>
            Your Rights{" "}
            <p>
              Depending on your location, you may have the following rights
              regarding your personal information:
            </p>
            <ul>
              <li>Access and obtain a copy of your data</li>
              <li>Correct inaccuracies in your data</li>
              <li>Request the deletion of your data</li>
              <li>Object to the processing of your data</li>
              <li>Withdraw consent for data processing</li>
            </ul>
          </li>
          <li>
            Third-Party Service Providers{" "}
            <p>
              We use third-party service providers to help us operate and
              improve The Ctrlroom app. These service providers may have access
              to your personal information to perform specific tasks on our
              behalf and are obligated not to disclose or use it for any other
              purpose. We have sourced service providers that are{" "}
              <a
                href="https://www.aicpa-cima.com/topic/audit-assurance/audit-and-assurance-greater-than-soc-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                SOC 2{" "}
              </a>
              compliant where possible. The third-party service providers we use
              include:
            </p>
            <ul>
              <li>
                Hosting - Amazon Web Services, Digital Ocean, Google Cloud
                Platform
              </li>
              <li>Data Storage: tinybird.co</li>
              <li>Authorisation: Google Firebase</li>
              <li>Analytics: Google Analytics</li>
              <li>Email Services: Sendgrid</li>
              <li>Chatbot: Crisp</li>
              <li>Miscellaneous: Typeform</li>
            </ul>
          </li>
          <li>
            Changes to This Privacy Policy{" "}
            <p>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on the
              Website and updating the "Effective Date" at the top.
            </p>
          </li>
          <li>
            Contact Information{" "}
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at{" "}
              <a href="mailto:support@ctrlroom.ai">support@ctrlroom.ai</a>
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
